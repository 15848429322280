import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage';

function App() {
    return (
        <div>
            <Navbar />
            <LandingPage />
        </div>
    );
}

export default App;
